import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'publicKeys'

const VIEW_ADMIN = 'admin'
const VIEW_SUPPORT = 'support'
const VIEW_ADVISOR = 'advisor'
const VIEW_MANAGER = 'manager'
const VIEW_USER = 'user'

class PublicKeyService {
  constructor (view) {
    this.view = view
  }

  count (params = {}) {
    params.view = this.view
    params.type = 'metadata'
    return DefaultApiService.count(ENDPOINT, params)
  }

  list (params = {}) {
    params.view = this.view
    params.type = 'table'
    return DefaultApiService.list(ENDPOINT, params)
  }

  get (id, params = {}) {
    params.view = this.view
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  create (data, params = {}) {
    params.view = this.view
    return DefaultApiService.create(ENDPOINT, data, params)
  }

  update (data, params = {}) {
    params.view = this.view
    return DefaultApiService.update(ENDPOINT, data, params)
  }

  delete (id, params = {}) {
    params.view = this.view
    return DefaultApiService.delete(ENDPOINT, id, params)
  }
}

export default PublicKeyService
export const publicKeyServiceForUserView = new PublicKeyService(VIEW_USER)
export const publicKeyServiceForManagerView = new PublicKeyService(VIEW_MANAGER)
export const publicKeyServiceForAdvisorView = new PublicKeyService(VIEW_ADVISOR)
export const publicKeyServiceForSupportView = new PublicKeyService(VIEW_SUPPORT)
export const publicKeyServiceForAdminView = new PublicKeyService(VIEW_ADMIN)
