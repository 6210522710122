<template>
  <div :class="`overflow-auto mx-${smallMargin ? 0 : 2} mx-md-${smallMargin ? 3 : 5}`">
    <div class="d-md-flex my-3">
      <b-button @click="$emit('goBack')" variant="secondary" class="mr-1">
        <b-icon-arrow-return-left shift-v="-2" class="mr-1"/>{{ $t('back.label') }}
      </b-button>
      <b-button type="submit" variant="primary" class="mr-1">
        <b-icon-arrow-up shift-v="-1" class="mr-1"/> {{ $t('submit.label') }}
      </b-button>
      <b-button type="reset" variant="warning" class="mr-1">
        <b-icon-arrow-counterclockwise shift-v="-1" class="mr-1"/>{{ $t('reset.label') }}
      </b-button>
      <b-button v-if="hasDeleteButton" @click="$emit('delete')" variant="danger" class="mr-1">
        <b-icon-x scale="1.4" shift-v="-1" class="mr-1"/>{{ $t('delete.label') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
export default {
  name: 'FormButtons',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  props: {
    hasDeleteButton: {
      type: Boolean,
      default: false
    },
    smallMargin: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
</style>
