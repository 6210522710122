<template>
  <div id="userAccounts">
    <div class="d-md-flex justify-content-md-around">
      <div><strong>{{ $t('comp.useraccounts.enabled.label') }}:</strong> {{ user?.enabled ? $t('yes.label') : $t('no.label') }}</div>
      <div><strong>{{ $t('comp.useraccounts.accountexpired.label') }}:</strong> {{ user?.accountExpired ? $t('yes.label') : $t('no.label') }}</div>
      <div><strong>{{ $t('comp.useraccounts.lastLogin.label') }}:</strong> {{ formatDate(user?.lastLogin) }}</div>
    </div>

    <hr/>

    <b-row cols="2" cols-lg="4" class="mb-3">
      <b-col>
        <b-button @click="getAccountsForUser()" variant="primary" class="w-100 mb-2 mb-lg-0">
          <b-icon-arrow-repeat class="mr-1"/> {{ $t('comp.useraccounts.refreshButton.label') }}
        </b-button>
      </b-col>
      <b-col>
        <b-button @click="filterInactive()" variant="secondary" class="w-100 mb-2 mb-lg-0">
          <b-icon-filter-square       class="mr-1" v-if="filterInactiveState === false"/>
          <b-icon-filter-square-fill  class="mr-1" v-if="filterInactiveState === true"/>
          {{ $t('comp.useraccounts.filterinactive.label') }}
        </b-button>
      </b-col>
      <b-col>
        <b-button @click="filterActive()" variant="secondary" class="w-100">
          <b-icon-filter-square       class="mr-1" v-if="filterActiveState === false"/>
          <b-icon-filter-square-fill  class="mr-1" v-if="filterActiveState === true"/>
          {{ $t('comp.useraccounts.filteractive.label') }}
        </b-button>
      </b-col>
      <b-col>
        <b-button @click="filterNokey()" variant="secondary" class="w-100">
          <b-icon-filter-square       class="mr-1" v-if="filterNokeyState === false"/>
          <b-icon-filter-square-fill  class="mr-1" v-if="filterNokeyState === true"/>
          {{ $t('comp.useraccounts.filternokey.label') }}
        </b-button>
      </b-col>
    </b-row>

    <div v-if="accounts.length > 0">
      <b-card v-for="account in accounts" :key="account.username" no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-input-group class="d-md-flex flex-nowrap">
            <b-button v-if="(!account.otherProjects || account.otherProjects.length === 0)" block v-b-toggle="`account-${account.username}`" variant="light" class="text-left">
              <b-icon-person-circle class="mr-1"/>
              {{
                $t('comp.useraccounts.accountForUser.label', {
                  account: account?.username ? account.username : 'No Username',
                  title: account?.project?.label ? account.project.label : 'Unknown Title',
                  project: account?.project?.label ? account.project.key : 'Unknown ProjectId',
                })
              }}
              <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
              <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
            </b-button>
            <b-button v-else block v-b-toggle="`account-${account.username}`" variant="light" class="text-left">
              <b-icon-person-circle class="mr-1"/>
              {{
                $t('comp.useraccounts.accountForUser.label', {
                  account: account?.username ? account.username : 'No Username',
                  title: account.project.label + ', ' + account.otherProjects.map(project => project['label']).join(', '),
                  project: account.project.key + ', ' + account.otherProjects.map(project => project['key']).join(', ')
                })
              }}
              <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
              <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
            </b-button>
            <b-input-group-append class="d-flex align-items-center">
              <b-icon v-if="account.state === 'active' && account.willExpire"   class="ml-2 mr-1 cursor-help" icon="info-square" variant="warning" font-scale="1.5" :title="$t(`comp.useraccounts.state.willexpire.title`)"/>
              <b-icon v-else-if="account.state === 'active' && account.isGrace" class="ml-2 mr-1 cursor-help" icon="exclamation-square" variant="danger" font-scale="1.5" :title="$t(`comp.useraccounts.state.grace.title`)"/>
              <b-icon v-else-if="account.state === 'active'"   class="ml-2 mr-1 cursor-help" icon="check-square" variant="success" font-scale="1.5" :title="$t(`comp.useraccounts.state.${account.state}.title`)"/>
              <b-icon v-else-if="account.state === 'inactive'" class="ml-2 mr-1 cursor-help" icon="slash-square" variant="danger"  font-scale="1.5" :title="$t(`comp.useraccounts.state.${account.state}.title`)"/>
              <b-icon v-else-if="account.state === 'banned'"   class="ml-2 mr-1 cursor-help" icon="x-square"     variant="danger"  font-scale="1.5" :title="$t(`comp.useraccounts.state.${account.state}.title`)"/>
              <b-icon v-else                                   class="ml-2 mr-1 cursor-help" icon="question-square" variant="info" font-scale="1.5" :title="$t(`comp.useraccounts.state.${account.state}.title`)"/>

              <b-icon v-if="account.publicKeysFound == true"       class="mx-1 cursor-help" icon="check-square" variant="success" font-scale="1.5" :title="$t(`comp.useraccounts.publickeysfound.${account.publicKeysFound}.title`)"/>
              <b-icon v-else-if="account.publicKeysFound == false" class="mx-1 cursor-help" icon="slash-square" variant="danger"  font-scale="1.5" :title="$t(`comp.useraccounts.publickeysfound.${account.publicKeysFound}.title`)"/>

            </b-input-group-append>
          </b-input-group>
        </b-card-header>
        <b-collapse :id="`account-${account.username}`" accordion="accounts" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <AccountInfo :account="account" :view="view"/>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <div v-else>
      <b-alert variant="info" show>{{ $t('comp.useraccounts.noAccount.description') }}</b-alert>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'
import AccountInfo from '@/components/generic/info/AccountInfo'
import AccountService from '@/services/account.service'

export default {
  name: 'UserAccounts',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    AccountInfo
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      accountService: new AccountService(this.view),
      accounts: [],
      inactiveAccounts: [],
      activeAccounts: [],
      keyAccounts: [],
      filterInactiveState: false,
      filterActiveState: false,
      filterNokeyState: false
    }
  },
  created () {
    this.getAccountsForUser()
  },
  destroyed () {
    this.accounts = []
  },
  methods: {
    getAccountsForUser () {
      this.accountService.listForUser({ user: this.user.username }).then(
        response => {
          this.inactiveAccounts = []
          this.activeAccounts = []
          this.keyAccounts = []
          this.filterInactiveState = false
          this.filterActiveState = false
          this.filterNokeyState = false
          this.accounts = response
        }
      ).catch(
        error => {
          console.log(error)
        }
      )
    },
    filterInactive () {
      if (this.filterInactiveState === false) {
        this.inactiveAccounts = this.accounts.filter(acc => acc.state === 'inactive')
        this.accounts = this.accounts.filter(acc => acc.state !== 'inactive')
        this.filterInactiveState = true
      } else {
        this.accounts = this.accounts.concat(this.inactiveAccounts)
        this.inactiveAccounts = []
        this.filterInactiveState = false
      }
    },
    filterActive () {
      if (this.filterActiveState === false) {
        this.activeAccounts = this.accounts.filter(acc => acc.state === 'active')
        this.accounts = this.accounts.filter(acc => acc.state !== 'active')
        this.filterActiveState = true
      } else {
        this.accounts = this.accounts.concat(this.activeAccounts)
        this.activeAccounts = []
        this.filterActiveState = false
      }
    },
    filterNokey () {
      if (this.filterNokeyState === false) {
        this.keyAccounts = this.accounts.filter(acc => acc.publicKeysFound === true)
        this.accounts = this.accounts.filter(acc => acc.publicKeysFound === false)
        this.filterNokeyState = true
      } else {
        this.accounts = this.accounts.concat(this.keyAccounts)
        this.keyAccounts = []
        this.filterNokeyState = false
      }
    },
    formatDate (date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm') : '-'
    }
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
