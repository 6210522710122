<template>
  <div id="usageProgress">
    <div v-if="hasCpuData || hasGpuData" class="text-center">
      <span v-if="hasCpuData">
        <small>CPU: {{ cpuPercent }}%</small>
        <b-progress  :max="progressData.cpu.max" :value="progressData.cpu.data" :variant="cpuColor" height="0.5rem" class="w-auto"/>
      </span>
      <span v-if="hasGpuData">
        <small>GPU: {{ gpuPercent }}%</small>
        <b-progress :max="progressData.gpu.max" :value="progressData.gpu.data" :variant="gpuColor" height="0.5rem" class="w-auto"/>
      </span>
    </div>
    <div v-else class="text-center">
      -
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import UsageService from '@/services/usage.service'

export default {
  name: 'UsageProgress',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {},
  props: {
    view: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: null
    },
    project: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      progressData: this.data,
      hasCpuData: (!!this?.data?.cpu),
      hasGpuData: (!!this?.data?.gpu)
    }
  },
  watch: {
    data: function () {
      this.progressData = { ...this.data }
      this.hasCpuData = (!!this?.data?.cpu)
      this.hasGpuData = (!!this?.data?.gpu)
    }
  },
  computed: {
    cpuPercent () {
      if (this?.progressData?.cpu) {
        return ((this.progressData.cpu.data / this.progressData.cpu.max) * 100).toFixed(1)
      } else {
        return 0
      }
    },
    gpuPercent () {
      if (this?.progressData?.gpu) {
        return ((this.progressData.gpu.data / this.progressData.gpu.max) * 100).toFixed(1)
      } else {
        return 0
      }
    },
    cpuColor () {
      if (this.cpuPercent < 60) {
        return 'success'
      } else if (this.cpuPercent >= 60 && this.cpuPercent < 90) {
        return 'warning'
      } else {
        return 'danger'
      }
    },
    gpuColor () {
      if (this.gpuPercent < 60) {
        return 'success'
      } else if (this.gpuPercent >= 60 && this.gpuPercent < 90) {
        return 'warning'
      } else {
        return 'danger'
      }
    }
  },
  created () {
    if (this.view && this.project) {
      this.populateProgress(this.view, this.project)
    }
  },
  methods: {
    populateProgress (view, target) {
      const usageService = new UsageService(view)
      usageService.getDataForProgress(target).then(
        response => {
          // console.log('Found ' + view + ' -> ' + type + ' pieplot data for ' + resourceType)
          if (!_.isEmpty(response)) {
            this.progressData = response
            this.hasCpuData = (!!this?.progressData?.cpu)
            this.hasGpuData = (!!this?.progressData?.gpu)
          }
        }
      ).catch(
        error => {
          console.log('Error while requesting progress data as ' + view + ': ' + error)
        }
      )
    },
    repopulateProgress () {
      this.hasCpuData = false
      this.hasGpuData = false
      this.progressData = null
      this.populateProgress(this.view, this.target)
    }
  }
}
</script>
