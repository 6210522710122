<template>
  <div id="projectAccounts">
    <div class="d-md-flex justify-content-md-around">
      <div class="text-capitalize"><strong>{{ $t('comp.projectaccounts.state.label') }}:</strong> {{ $t(`states.${project.state}.label`) }}</div>
      <div><strong>{{ $t('comp.projectaccounts.validFrom.label') }}:</strong> {{ formatDate(project.validFrom) }}</div>
      <div><strong>{{ $t('comp.projectaccounts.validUntil.label') }}:</strong> {{ formatDate(project.validUntil) }}</div>
    </div>

    <hr/>

    <b-row cols="2" cols-lg="4" class="mb-3">
      <b-col>
        <b-button @click="getAccountsForProject()" variant="primary" class="w-100 mb-2 mb-lg-0">
          <b-icon-arrow-repeat class="mr-1"/> {{ $t('comp.projectaccounts.refreshButton.label') }}
        </b-button>
      </b-col>
      <b-col>
        <b-button @click="filterInactive()" variant="secondary" class="w-100 mb-2 mb-lg-0">
          <b-icon-filter-square       class="mr-1" v-if="filterInactiveState === false"/>
          <b-icon-filter-square-fill  class="mr-1" v-if="filterInactiveState === true"/>
          {{ $t('comp.projectaccounts.filterinactive.label') }}
        </b-button>
      </b-col>
      <b-col>
        <b-button @click="filterActive()" variant="secondary" class="w-100">
          <b-icon-filter-square       class="mr-1" v-if="filterActiveState === false"/>
          <b-icon-filter-square-fill  class="mr-1" v-if="filterActiveState === true"/>
          {{ $t('comp.projectaccounts.filteractive.label') }}
        </b-button>
      </b-col>
      <b-col>
        <b-button @click="filterExpiry()" variant="secondary" class="w-100">
          <b-icon-filter-square       class="mr-1" v-if="filterExpiryState === false"/>
          <b-icon-filter-square-fill  class="mr-1" v-if="filterExpiryState === true"/>
          {{ $t('comp.projectaccounts.filterexpiry.label') }}
        </b-button>
      </b-col>
    </b-row>

    <b-form v-if="view === 'admin'" @submit="updateSelected">
      <b-row cols="2" cols-lg="4" class="mb-3">
        <b-col>
          <b-button @click="selectAll()" variant="primary" class="w-100 mb-2 mb-lg-0">
            <b-icon-list-check class="mr-1" shift-v="-1"/> {{ $t('comp.projectaccounts.selectAll.label') }}
          </b-button>
        </b-col>
        <b-col>
          <b-form-datepicker no-flip id="valid-until" :showDecadeNav="false" v-model="updateForm.selectedFields.validUntil"
                             v-bind="datePickerLabels || {}" :value-as-date="true" class="w-100 mb-2 mb-lg-0"/>
        </b-col>
        <b-col>
          <StateSelect :selected-state.sync="updateForm.selectedFields.state" entity="account" view="admin" class="w-100 mb-2 mb-lg-0"/>
        </b-col>
        <b-col>
          <b-button type="submit" variant="success" class="w-100 mb-2 mb-lg-0" :disabled="selectedCount === 0">
            <b-icon-save class="mr-1"/>
            {{ $t('comp.projectaccounts.updateSelected.label') }}
            {{ selectedCount !== 0 ? `(${selectedCount})` : ''}}
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <div v-if="accounts.length > 0">
      <b-card v-for="account in accounts" :key="account.username" no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-input-group class="d-md-flex flex-nowrap">
            <b-input-group-prepend v-if="view === 'admin'" class="d-flex align-items-center ml-1 mr-0">
              <b-form-checkbox
                :id="`checkbox-${account.username}`"
                :name="`checkbox-${project.projectId}`"
                :value="account.username"
                v-model="updateForm.selectedAccounts"
              />
            </b-input-group-prepend>
            <b-button block v-b-toggle="`account-${account.username}`" variant="light" class="text-left pl-1">
              <b-icon-person-circle class="mr-1" shift-v="-1"/>
              {{
                $t('comp.projectaccounts.accountForProject.label', {
                  account: account?.username ? account.username   : 'No Username',
                  user: account?.user?.label ? account.user.label : 'Unknown User'
                })
              }}
              <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
              <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
            </b-button>
            <b-input-group-append class="d-flex align-items-center">
              <b-icon v-if="account.state === 'active' && account.willExpire"   class="ml-2 mr-1 cursor-help" icon="info-square" variant="warning" font-scale="1.5" :title="$t(`comp.projectaccounts.state.willexpire.title`)"/>
              <b-icon v-else-if="account.state === 'active' && account.isGrace" class="ml-2 mr-1 cursor-help" icon="exclamation-square" variant="danger" font-scale="1.5" :title="$t(`comp.projectaccounts.state.grace.title`)"/>
              <b-icon v-else-if="account.state === 'active'"   class="ml-2 mr-1 cursor-help" icon="check-square" variant="success" font-scale="1.5" :title="$t(`comp.projectaccounts.state.${account.state}.title`)"/>
              <b-icon v-else-if="account.state === 'inactive'" class="ml-2 mr-1 cursor-help" icon="slash-square" variant="danger"  font-scale="1.5" :title="$t(`comp.projectaccounts.state.${account.state}.title`)"/>
              <b-icon v-else-if="account.state === 'banned'"   class="ml-2 mr-1 cursor-help" icon="x-square"     variant="danger"  font-scale="1.5" :title="$t(`comp.projectaccounts.state.${account.state}.title`)"/>
              <b-icon v-else                                   class="ml-2 mr-1 cursor-help" icon="question-square" variant="info" font-scale="1.5" :title="$t(`comp.projectaccounts.state.${account.state}.title`)"/>

              <b-icon v-if="account.publicKeysFound == true"       class="mx-1 cursor-help" icon="check-square" variant="success" font-scale="1.5" :title="$t(`comp.projectaccounts.publickeysfound.${account.publicKeysFound}.title`)"/>
              <b-icon v-else-if="account.publicKeysFound == false" class="mx-1 cursor-help" icon="slash-square" variant="danger"  font-scale="1.5" :title="$t(`comp.projectaccounts.publickeysfound.${account.publicKeysFound}.title`)"/>

              <b-icon v-if="account.state !== 'active'" class="mx-1 cursor-help" icon="slash-square" variant="danger" font-scale="1.5" :title="$t(`comp.projectaccounts.state.${account.state}.title`)"/>
              <b-icon v-else-if="account.validityState == 'equal'" class="mx-1 cursor-help" icon="check-square" variant="success" font-scale="1.5" :title="$t(`comp.projectaccounts.validity.${account.validityState}.title`)"/>
              <b-iconstack v-else-if="account.validityState == 'less'" class="mx-1 cursor-help" variant="warning" font-scale="1.5" :title="$t(`comp.projectaccounts.validity.${account.validityState}.title`)">
                <b-icon stacked icon="square"/>
                <b-icon stacked icon="chevron-left" scale="0.75"/>
              </b-iconstack>
              <b-iconstack v-else-if="account.validityState == 'greater'" class="mx-1 cursor-help" variant="danger" font-scale="1.5" :title="$t(`comp.projectaccounts.validity.${account.validityState}.title`)">
                <b-icon stacked icon="square"/>
                <b-icon stacked icon="chevron-right" scale="0.75"/>
              </b-iconstack>
              <b-iconstack v-else class="mx-1 cursor-help" variant="info" font-scale="1.5" :title="$t(`comp.projectaccounts.validity.${account.validityState}.title`)">
                <b-icon stacked icon="square"/>
                <b-icon stacked icon="asterisk" scale="0.5"/>
              </b-iconstack>
            </b-input-group-append>
          </b-input-group>
        </b-card-header>
        <b-collapse :id="`account-${account.username}`" accordion="accounts" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <AccountInfo :account="account" :view="view"/>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <div v-else>
      <b-alert variant="info" show class="mx-3">{{ $t('comp.projectaccounts.noAccount.description') }}</b-alert>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'
import datepickerMixin from '@/mixins/datepicker.mixin'
import AccountInfo from '@/components/generic/info/AccountInfo'
import AccountService from '@/services/account.service'
import StateSelect from '@/components/generic/select/StateSelect'

export default {
  name: 'ProjectAccounts',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, datepickerMixin],
  components: {
    AccountInfo,
    StateSelect
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      accountService: new AccountService(this.view),
      accounts: [],
      inactiveAccounts: [],
      expiryAccounts: [],
      activeAccounts: [],
      filterInactiveState: false,
      filterActiveState: false,
      filterExpiryState: false,
      updateForm: {
        selectedAccounts: [],
        selectedFields: {
          validUntil: null,
          state: ''
        }
      }
    }
  },
  computed: {
    selectedCount () {
      return this.updateForm.selectedAccounts.length
    }
  },
  created () {
    this.getAccountsForProject()
    this.updateForm.selectedFields.state = this?.project?.state ? this.project.state : ''
    this.updateForm.selectedFields.validUntil = this?.project?.validUntil ? new Date(this.project.validUntil) : null // Format to JSDate for display
  },
  destroyed () {
    this.accounts = []
    this.updateForm = {
      selectedAccounts: [],
      selectedFields: null
    }
  },
  methods: {
    getAccountsForProject () {
      this.accountService.listForProject({ projectId: this.project.projectId }).then(
        response => {
          // Reset Vars
          this.inactiveAccounts = []
          this.activeAccounts = []
          this.expiryAccounts = []
          this.filterInactiveState = false
          this.filterActiveState = false
          this.filterExpiryState = false
          // Reset Form Accounts
          this.updateForm.selectedAccounts = []
          // Set Response
          this.accounts = response
        }
      ).catch(
        error => {
          console.log(error)
        }
      )
    },
    filterInactive () {
      this.updateForm.selectedAccounts = []
      if (this.filterInactiveState === false) {
        this.inactiveAccounts = this.accounts.filter(acc => acc.state !== 'active')
        this.accounts = this.accounts.filter(acc => acc.state === 'active')
        this.filterInactiveState = true
      } else {
        this.accounts = this.accounts.concat(this.inactiveAccounts)
        this.inactiveAccounts = []
        this.filterInactiveState = false
      }
    },
    filterActive () {
      this.updateForm.selectedAccounts = []
      if (this.filterActiveState === false) {
        this.activeAccounts = this.accounts.filter(acc => acc.state === 'active')
        this.accounts = this.accounts.filter(acc => acc.state !== 'active')
        this.filterActiveState = true
      } else {
        this.accounts = this.accounts.concat(this.activeAccounts)
        this.activeAccounts = []
        this.filterActiveState = false
      }
    },
    filterExpiry () {
      this.updateForm.selectedAccounts = []
      if (this.filterExpiryState === false) {
        this.expiryAccounts = this.accounts.filter(acc => (acc.willExpire === false && acc.isGrace === false))
        this.accounts = this.accounts.filter(acc => (acc.willExpire === true || acc.isGrace === true))
        this.filterExpiryState = true
      } else {
        this.accounts = this.accounts.concat(this.expiryAccounts)
        this.expiryAccounts = []
        this.filterExpiryState = false
      }
    },
    selectAll () {
      if (this.updateForm.selectedAccounts.length === 0) {
        this.updateForm.selectedAccounts = this.accounts.map(acc => acc.username)
      } else {
        this.updateForm.selectedAccounts = []
      }
    },
    updateSelected (evt) {
      evt.preventDefault()
      // Only update accounts if any selected (Backup Failsafe)
      if (this.updateForm.selectedAccounts.length !== 0) {
        this.accountService.updateMany(this.updateForm).then(
          response => {
            if (response?.saved && response?.failed) {
              this.makeToast(
                this.$i18n.t('updatedPartial.text', { id: `${response.saved} Accounts`, miss: response.failed.join(',') }),
                this.$i18n.t('comp.projectAccounts.updatedPartial.title'),
                'warning'
              )
            } else {
              this.makeToast(
                this.$i18n.t('updated.text', { id: `${response} Accounts`, code: response.code }),
                this.$i18n.t('comp.projectAccounts.updated.title'),
                'success'
              )
            }
            // Reset & Update Accs
            this.getAccountsForProject()
          }
        ).catch(
          error => {
            console.log('Error:', error)
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: error.message, id: `${this.updateForm.selectedAccounts.length} Accounts` }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        )
      } else {
        this.makeToast(
          this.$i18n.t('skip.text'),
          this.$i18n.t('result.skip.title'),
          'info'
        )
      }
    },
    formatDate (date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm') : '-'
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
