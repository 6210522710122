<template>
  <div class="requests" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('requests.title') }}</h1>
    <div v-if="requestForm.isVisible">
      <b-form @submit="onSubmit" @reset="onReset">
        <FormButtons @goBack="goBack" :has-delete-button="!!requestForm.formCache.requestId" @delete="deleteRequest(requestForm.form)"/>
        <RequestForm :request="requestForm" :action="requestForm.action" :view="view"/>
      </b-form>
    </div>
    <div v-else class="overflow-auto px-2 px-md-5">
      <div class="row justify-content-between justify-content-md-start mt-lg-3">
        <div class="col-6 col-md-auto mb-3 pr-md-0">
          <b-button @click="addRequest" variant="success">
            <b-icon-plus/> {{ $t('comp.requests.addRequest.label') }}
          </b-button>
          </div>
          <div class="col-6 col-md-auto mb-3">
          <b-button @click="showPendingOnly" variant="primary" class="ml-3">
            <b-icon-funnel/> {{ $t('comp.requests.showPendingOnly.label') }}
          </b-button>
        </div>
      </div>
      <TableHeader @refresh="refresh" :filter.sync="requestTable.filter" :per-page.sync="requestTable.perPage" :current-page.sync="requestTable.currentPage"
                   :rows="requestTable.rows" :total-rows="requestTable.totalRows" table-id="requests-table"/>
      <b-table responsive id="requests-table" ref="requests-table" :busy.sync="requestTable.isBusy" :fields="fields" :per-page="requestTable.perPage"
               :current-page="requestTable.currentPage" :filter="requestTable.filter" :items="requestItemProvider" :sort-by.sync="requestTable.sortBy"
               :sort-desc.sync="requestTable.sortDesc" small striped hover>
        <template v-slot:cell(actions)="data">
          <b-button @click="editRequest(data.item)" :title="$t('comp.requests.edit.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-pencil class="mr-1" variant="primary"/>
          </b-button>
          <b-button @click="deleteRequest(data.item)" :title="$t('comp.requests.delete.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-trash class="mr-1" variant="danger"/>
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { requestServiceForAdminView } from '@/services/request.service'
import TableHeader from '@/components/generic/helper/TableHeader'
import FormButtons from '@/components/generic/helper/FormButtons'
import RequestForm from '@/components/generic/form/RequestForm'

export default {
  name: 'Requests',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    RequestForm,
    TableHeader,
    FormButtons
  },
  data () {
    return {
      view: 'admin',
      requestForm: {
        action: null,
        form: null,
        formCache: null,
        isVisible: false,
        emptyForm: {
          requestId: '',
          forUser: null,
          forProject: null,
          message: '',
          state: 'pending'
        }
      },
      requestTable: {
        isBusy: false,
        perPage: 10,
        currentPage: 1,
        sortBy: 'createdAt',
        sortDesc: true,
        rows: 0,
        totalRows: 0,
        filter: ''
      }
    }
  },
  computed: {
    fields () {
      const fields = [
        { key: 'actions', label: this.$i18n.t('actions.label'), sortable: false },
        { key: 'requestId' },
        { key: 'forUser', select: true },
        { key: 'forProject', select: true },
        { key: 'state', state: true },
        { key: 'account' },
        { key: 'createdBy' },
        { key: 'createdAt', date: true }
      ]

      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = true
        }
        if (field.label == null) {
          field.label = this.$i18n.t(`comp.requests.${field.key}.label`)
        }
        if (field.date) {
          field.formatter = (value, key, item) => {
            return value ? moment(value).format('YYYY-MM-DD HH:mm') : ''
          }
          field.sortByFormatted = true
        }
        if (field.select) {
          field.sortKey = field.key + '.label'
          field.formatter = (value, key, item) => {
            return _.has(value, 'label') ? value.label : ''
          }
        }
        if (field.state) {
          field.formatter = (value) => {
            return this.$i18n.t(`states.${value}.label`)
          }
        }
      })
      return fields
    }
  },
  created () {
    requestServiceForAdminView.count({ filter: this.requestTable.filter }).then((response) => (this.requestTable.rows = response))
    requestServiceForAdminView.count().then((response) => (this.requestTable.totalRows = response))
  },
  methods: {
    refresh () {
      requestServiceForAdminView.count().then((response) => (this.requestTable.totalRows = response))
      if (this.$refs['requests-table']) {
        this.$refs['requests-table'].refresh()
      }
    },
    addRequest () {
      this.requestForm.action = 'add'
      this.requestForm.formCache = _.cloneDeep(this.requestForm.emptyForm)
      this.requestForm.form = _.cloneDeep(this.requestForm.formCache)
      this.requestForm.isVisible = true
    },
    editRequest (item) {
      this.requestForm.action = 'edit'
      this.requestForm.formCache = _.cloneDeep(item)
      this.requestForm.form = _.cloneDeep(this.requestForm.formCache)
      this.requestForm.isVisible = true
    },
    onSubmit (evt) {
      evt.preventDefault()
      if (this.requestForm.action === 'add') {
        requestServiceForAdminView.create(this.requestForm.form).then(
          response => {
            this.makeToast(
              this.$i18n.t('created.text', { id: response.requestId, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
            this.refresh()
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.requestForm.requestId }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      } else if (this.requestForm.action === 'edit') {
        requestServiceForAdminView.update(this.requestForm.form).then(
          response => {
            this.makeToast(
              this.$i18n.t('updated.text', { id: this.requestForm.form.requestId, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.requestForm.form.requestId }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.requestForm.form = _.cloneDeep(this.requestForm.formCache)
      // Trick to reset/clear native browser form validation state
      // this.requestForm.isVisible = false
      // this.$nextTick(() => {
      //  this.requestForm.isVisible = true
      // })
    },
    deleteRequest (request) {
      this.$bvModal.msgBoxConfirm(this.$i18n.t('sure.question'), {
        okVariant: 'danger',
        okTitle: this.$i18n.t('confirm.delete.label'),
        cancelTitle: this.$i18n.t('no.label')
      })
        .then(value => {
          if (value === true) {
            requestServiceForAdminView.delete(request.requestId).then(
              (response) => {
                this.makeToast(
                  this.$i18n.t('deleted.text', { id: request.requestId, code: response.code }),
                  this.$i18n.t('result.success.title'),
                  'success')
                if (this.requestForm.action === 'edit') { this.goBack() }
                this.refresh()
              }
            ).catch(
              error => this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: error.message, id: request.requestId }),
                this.$i18n.t('result.error.title'),
                'danger')
            )
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    goBack () {
      this.requestForm.isVisible = false
      this.requestForm.form = null
      this.requestForm.formCache = null
      this.requestForm.action = null
    },
    requestItemProvider (ctx) {
      return requestServiceForAdminView.list(ctx).then((data) => {
        this.requestTable.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    },
    showPendingOnly () {
      this.requestTable.filter = 'pending'
      this.refresh()
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>
</style>
