<template>
  <div class="publickeys" v-show="messagesAvailable">
    <b-form id="public-key-form" v-if="showForm && ((view === 'user') || (view === 'admin'))" @submit="onSubmit" @reset="onReset">
      <FormButtons @goBack="goBack" :has-delete-button="!!formReset.fingerprint" @delete="deletePublicKey(form)" :smallMargin="true"/>
      <b-card id="public-key-error" class="mx-0 mx-md-3 mb-3" :header="$t('comp.publickeys.error.label')"
              v-if="showError" bg-variant="warning">
        <b-card-text>
          {{sanityErrorText}}
        </b-card-text>
      </b-card>

      <div class="mx-0 mx-md-3 mb-3">
        <span>
          <b>{{ $t('comp.publickeys.format.info') }} </b>
          <a href="https://doc.nhr.fau.de/access/ssh-command-line/#generating-an-ssh-key-pair">{{ $t('comp.publickeys.format.docs') }}</a>
        </span>
      </div>

      <b-card class="mx-0 mx-md-3" :header="$t('comp.publickeys.add.label')">
        <b-form-group :label="$t('comp.publickeys.fingerprint.label')"
                      :description="$t('comp.publickeys.fingerprint.description')">
          <b-form-input v-model="form.fingerprint" required disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.publickeys.fingerprintSHA256.label')"
                      :description="$t('comp.publickeys.fingerprintSHA256.description')">
          <b-form-input v-model="form.fingerprintSHA256" required disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.publickeys.keyAlias.label')"
                      :description="$t('comp.publickeys.keyAlias.description')">
          <b-form-input v-model="form.keyAlias" required/>
        </b-form-group>
        <b-form-group :label="$t('comp.publickeys.keyContent.label')"
                      :description="$t('comp.publickeys.keyContent.description')">
          <b-form-textarea id="keyContent" v-model="form.keyContent" required :disabled="formEdit" rows="3"/>
        </b-form-group>

        <b-button v-b-toggle="'collapseOptions'" variant="primary">
          <span class="when-opened"><b-icon-arrow-up/> {{ $t('comp.publickeys.hideOptions.label') }}</span>
          <span class="when-closed"><b-icon-arrow-down/> {{ $t('comp.publickeys.showOptions.label') }}</span>
        </b-button>

        <b-collapse id="collapseOptions" class="mt-2">
          <b-card :title="$t('comp.publickeys.options.label')" class="my-3">
            <b-card-text>
              <b-form-group :label="$t('comp.publickeys.options.from.label')" label-for="from"
                            :description="$t('comp.publickeys.options.from.description')">
                <b-form-input type="text" v-model="form.options.from"/>
              </b-form-group>
              <b-form-group :label="$t('comp.publickeys.options.command.label')" label-for="command"
                            :description="$t('comp.publickeys.options.command.description')">
                <b-form-input type="text" v-model="form.options.command"/>
              </b-form-group>
              <b-form-checkbox id="nopty" v-model="form.options.noPty" name="nopty" switch>
                {{ $t('comp.publickeys.options.noPty.label') }}
              </b-form-checkbox>
              <b-form-checkbox id="noagentforwarding" v-model="form.options.noAgentForwarding"
                               name="noagentforwading" switch>
                {{ $t('comp.publickeys.options.noAgentForwarding.label') }}
              </b-form-checkbox>
              <b-form-checkbox id="noportforwading" v-model="form.options.noPortForwarding" name="noportforwarding"
                               switch>
                {{ $t('comp.publickeys.options.noPortForwarding.label') }}
              </b-form-checkbox>
              <b-form-checkbox id="nox11forwarding" v-model="form.options.noX11Forwarding" name="nox11forwarding"
                               switch>
                {{ $t('comp.publickeys.options.noX11Forwarding.label') }}
              </b-form-checkbox>
            </b-card-text>
          </b-card>
        </b-collapse>
      </b-card>
    </b-form>
    <div class="overflow-auto mx-0 mx-md-3" v-else>
      <b-card id="public-key-info" class="mb-2" :header="$t('comp.publickeys.info.label')"
              v-if="showInfo" bg-variant="info">
        <b-card-text>
          {{$t('comp.publickeys.info.text')}}
        </b-card-text>
      </b-card>
      <div class="d-md-flex my-3">
        <b-input-group v-if="(view === 'user' || view === 'admin') && account.state === 'active'">
          <b-button @click="addPublicKey" variant="success">
            <b-icon-plus/> {{ $t('comp.publickeys.add.label') }}
          </b-button>
          <b-input-group-append class="cursor-help">
            <b-button variant="primary" :id="`popover-${account.username}-publickeyformat`">
              <b-icon icon="info-circle" shift-v="-1"/>
            </b-button>
          </b-input-group-append>
          <b-popover :target="`popover-${account.username}-publickeyformat`" triggers="hover" placement="right">
            <template #title>{{ $t('comp.publickeys.format.title') }}</template>
            {{ $t('comp.publickeys.format.info') }}
            <a href="https://doc.nhr.fau.de/access/ssh-command-line/#generating-an-ssh-key-pair">{{ $t('comp.publickeys.format.docs') }}</a>
          </b-popover>
        </b-input-group>
        <div class="d-flex align-items-center" v-else>
          <b-icon-info-circle variant="secondary" class="mr-2"/>
          {{ $t('comp.publickeys.readonly.text') }}
        </div>
        <b-dropdown class="ml-auto" style="min-width:max-content;" split :text="showFormat">
          <b-dropdown-item-button @click="switchFormat('MD5')">{{ $t('comp.publickeys.showformat.md5.label') }}</b-dropdown-item-button>
          <b-dropdown-item-button @click="switchFormat('SHA256')">{{ $t('comp.publickeys.showformat.sha256.label') }}</b-dropdown-item-button>
        </b-dropdown>
      </div>
      <b-table responsive id="public-keys-table" ref="public-keys-table" :fields="fields" :items="publicKeyItemProvider"
               small striped hover>
        <template v-slot:cell(actions)="data">
          <b-button @click="editPublicKey(data.item)" :title="$t('comp.publickeys.edit.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-pencil class="mr-1" variant="primary"/>
          </b-button>
          <b-button @click="deletePublicKey(data.item)" :title="$t('comp.publickeys.delete.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-trash class="mr-1" variant="danger"/>
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import FormButtons from '@/components/generic/helper/FormButtons'
import PublicKeyService from '@/services/publicKey.service'
import { i18nMixin } from '@/mixins/i18n.mixin'
import _ from 'lodash'

export default {
  name: 'PublicKeys',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    FormButtons
  },
  props: {
    account: {
      type: Object
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      totalRows: 0,
      filter: '',
      form: null,
      formEdit: false,
      formReset: null,
      showForm: false,
      showError: false,
      showInfo: false,
      sanityErrorText: '',
      showFormat: 'MD5',
      emptyForm: {
        fingerprint: '',
        fingerprintSHA256: '',
        keyAlias: '',
        keyContent: '',
        options: {
          from: '',
          command: '',
          noPty: false,
          noAgentForwarding: false,
          noPortForwarding: false,
          noX11Forwarding: false
        }
      }
    }
  },
  computed: {
    fields () {
      let fields = []
      if ((this.view === 'user') || (this.view === 'admin')) {
        fields = [
          { key: 'actions', label: this.$i18n.t('actions.label') },
          { key: 'keyAlias', label: this.$i18n.t('comp.publickeys.keyAlias.label') },
          { key: 'fingerprint', label: this.$i18n.t('comp.publickeys.fingerprint.label'), format: 'MD5' },
          { key: 'fingerprintSHA256', label: this.$i18n.t('comp.publickeys.fingerprintSHA256.label'), format: 'SHA256' }
        ]
      } else {
        fields = [
          { key: 'keyAlias', label: this.$i18n.t('comp.publickeys.keyAlias.label') },
          { key: 'fingerprint', label: this.$i18n.t('comp.publickeys.fingerprint.label'), format: 'MD5' },
          { key: 'fingerprintSHA256', label: this.$i18n.t('comp.publickeys.fingerprintSHA256.label'), format: 'SHA256' }
        ]
      }
      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = false
        }
        if (field.format) {
          field.thClass = `${this.showFormat === field.format ? '' : 'd-none'}`
          field.tdClass = `${this.showFormat === field.format ? '' : 'd-none'}`
        }
      })
      return fields
    },
    formCardHeader () {
      if (this.formEdit === true) {
        return this.$i18n.t('comp.publickeys.edit.label')
      } else {
        return this.$i18n.t('comp.publickeys.add.label')
      }
    }
  },
  methods: {
    refresh () {
      if (this.$refs['public-keys-table']) {
        this.$refs['public-keys-table'].refresh()
      }
    },
    addPublicKey () {
      this.formEdit = false
      this.formReset = _.cloneDeep(this.emptyForm)
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
      this.showInfo = false
    },
    editPublicKey (publicKey) {
      this.formEdit = true
      this.formReset = _.cloneDeep(publicKey)
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
      this.showInfo = false
    },
    onSubmit (evt) {
      const publicKeyService = new PublicKeyService(this.view)
      evt.preventDefault()
      const params = { account: this.account.username }
      if (this.formEdit) {
        publicKeyService.update(this.form, params).then(
          response => {
            this.makeToast(
              this.$i18n.t('updated.text', { id: this.form.keyAlias, code: response.code }),
              this.$i18n.t('comp.publickeys.updated.title'),
              'success'
            )
            this.showError = false
            this.showInfo = true
            this.sanityErrorText = ''
            this.goBack()
            this.refresh()
          }
        ).catch(
          error => {
            let errorMsg = error.message
            if (!errorMsg) {
              errorMsg = this.$i18n.t('publickey.error')
              this.showError = true
              this.showInfo = false
              this.sanityErrorText = this.$i18n.t(error.request.response)
            }
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: errorMsg, id: this.form.keyAlias }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        )
      } else {
        publicKeyService.create(this.form, params).then(
          response => {
            this.makeToast(
              this.$i18n.t('created.text', { id: this.form.keyAlias, code: response.code }),
              this.$i18n.t('comp.publickeys.created.title'),
              'success'
            )
            this.showError = false
            this.showInfo = true
            this.sanityErrorText = ''
            this.goBack()
            this.refresh()
          }
        ).catch(
          error => {
            let errorMsg = error.message
            if (!errorMsg) {
              errorMsg = this.$i18n.t('publickey.error')
              this.showError = true
              this.showInfo = false
              this.sanityErrorText = this.$i18n.t(error.request.response)
            }
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: errorMsg, id: this.form.keyAlias }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        )
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.form = _.cloneDeep(this.formReset)
      // Reset publickey sanity warning
      this.showError = false
      this.showInfo = false
      this.sanityErrorText = ''
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    deletePublicKey (publicKey) {
      const publicKeyService = new PublicKeyService(this.view)
      this.$bvModal.msgBoxConfirm(
        this.$i18n.t('comp.publickeys.delete.description', { alias: publicKey.keyAlias }),
        {
          title: this.$i18n.t('comp.publickeys.delete.label') + '?',
          okVariant: 'danger',
          okTitle: this.$i18n.t('confirm.delete.label'),
          cancelTitle: this.$i18n.t('no.label')
        })
        .then(value => {
          if (value === true) {
            const params = { account: this.account.username }
            publicKeyService.delete(publicKey.fingerprint, params).then(
              (response) => {
                this.makeToast(
                  this.$i18n.t('deleted.text', { id: publicKey.keyAlias, code: response.code }),
                  this.$i18n.t('comp.publickeys.deleted.title'),
                  'success'
                )
                if (this.formEdit) { this.goBack() }
                this.refresh()
              }
            ).catch(
              error => this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: error.message, id: publicKey.keyAlias }),
                this.$i18n.t('result.error.title'),
                'danger'
              )
            )
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    goBack () {
      this.showForm = false
      this.form = null
      this.formReset = null
    },
    publicKeyItemProvider (ctx) {
      const publicKeyService = new PublicKeyService(this.view)
      const params = { account: this.account.username }
      return publicKeyService.list(params).then((data) => {
        this.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    },
    switchFormat (format) {
      this.showFormat = format
    }
  }
}
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
