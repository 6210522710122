<template>
  <div id="userUsageInfo">
    <div v-if="accounts.length > 0">
      <b-form-group :description="$t('comp.userusageinfo.selectyear.description')">
          <b-form-select v-model="selectedYear" :options="yearOptions"></b-form-select>
      </b-form-group>
      <div class="accordion" role="tablist">
        <b-card v-for="(account, index) in accounts" :key="account.key" no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="`userusage-${account.key}`" variant="light" class="text-left">
              <b-icon-person-circle class="mr-2"/>
              {{ account.label }}
              <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
              <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
            </b-button>
          </b-card-header>
          <b-collapse v-if="index == 0" :id="`userusage-${account.key}`" accordion="userusages" role="tabpanel" visible>
            <b-card-body class="pb-1" v-if="account.usages.some(u => u.forYear === selectedYear)">
              <b-card-text v-if="account.usages.length > 0" class="d-md-flex flex-wrap"> <!-- justify-content-center -->
                <div v-for="usage in account.usages" :key="usage.usageId" :class="(usage.forYear == selectedYear) ? 'w-50' : 'w-auto'">
                  <b-card v-if="usage.forYear == selectedYear" class="mb-3 mr-3">
                    <template #header>
                      <b-icon-hdd-rack class="mr-2"/> {{ usage.resource }}
                    </template>
                    <b-card-text>
                      <li v-if="selectedYear == thisYear"><strong>{{ $t('comp.userusageinfo.thismonth.label') }}:</strong> {{ usage.thisMonth }} {{ usage.unit }}</li>
                      <li><strong>{{ $t('comp.userusageinfo.nummonths.label') }}:</strong> {{ usage.numMonths }}</li>
                      <li v-if="usage.yearTotal"><strong>{{ $t('comp.userusageinfo.yeartotal.label') }}:</strong> {{ usage.yearTotal }} {{ usage.unit }}</li>
                    </b-card-text>
                  </b-card>
                </div>
              </b-card-text>
              <b-card-text v-else>
                <div class="ml-4">
                  {{ $t('comp.userusageinfo.nodata', {type: this.type, target: account}) }}
                </div>
              </b-card-text>
            </b-card-body>
            <b-card-body v-else>
              <b-card-text>
                {{ $t('comp.userusageinfo.resource.nodatacard', {year: selectedYear}) }}
              </b-card-text>
            </b-card-body>
          </b-collapse>
          <b-collapse v-else :id="`userusage-${account.key}`" accordion="userusages" role="tabpanel">
            <b-card-body class="pb-1" v-if="account.usages.some(u => u.forYear === selectedYear)">
              <b-card-text v-if="account.usages.length > 0" class="d-md-flex flex-wrap"> <!-- justify-content-center -->
                <div v-for="usage in account.usages" :key="usage.usageId" :class="(usage.forYear == selectedYear) ? 'w-50' : 'w-auto'">
                  <b-card v-if="usage.forYear == selectedYear" class="mb-3 mr-3">
                    <template #header>
                      <b-icon-hdd-rack class="mr-2"/> {{ usage.resource }}
                    </template>
                    <b-card-text>
                      <li v-if="selectedYear == thisYear"><strong>{{ $t('comp.userusageinfo.thismonth.label') }}:</strong> {{ usage.thisMonth }} {{ usage.unit }}</li>
                      <li v-if="usage.yearTotal"><strong>{{ $t('comp.userusageinfo.yeartotal.label') }}:</strong> {{ usage.yearTotal }} {{ usage.unit }}</li>
                      <li><strong>{{ $t('comp.userusageinfo.nummonths.label') }}:</strong> {{ usage.numMonths }}</li>
                    </b-card-text>
                  </b-card>
                </div>
              </b-card-text>
              <b-card-text v-else>
                <div class="ml-4">
                  {{ $t('comp.userusageinfo.nodata', {type: this.type, target: account}) }}
                </div>
              </b-card-text>
            </b-card-body>
            <b-card-body v-else>
              <b-card-text>
                {{ $t('comp.userusageinfo.resource.nodatacard', {year: selectedYear}) }}
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <hr class="my-3" />
      <UsageBars :view="this.view" :type="this.type" :target="user" :queryYear="selectedYear"/>
    </div>
    <div v-else>
      <div class="ml-4">
        {{ $t('comp.userusageinfo.noaccounts', {type: this.type, target: user}) }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import UsageService from '@/services/usage.service'
import UsageBars from '@/components/generic/chart/UsageBars'
import { i18nMixin } from '@/mixins/i18n.mixin'
import yearSelectMixin from '@/mixins/yearSelect.mixin'

export default {
  name: 'UserUsageInfo',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, yearSelectMixin],
  components: {
    UsageBars
  },
  props: {
    user: {
      type: String,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      type: 'user',
      accounts: []
    }
  },
  created () {
    this.getUsages(this.user) // Put after if condition?
  },
  computed: {
  },
  methods: {
    getUsages (username) {
      if (this.view === 'user') {
        return this.$store.dispatch({ type: 'user/getUsages', targetId: username }).then(
          response => {
            if (!_.isEmpty(response)) {
              // this.hasData = true
              this.accounts = response
            }
          }
        ).catch(
          error => {
            console.log('Error while requesting user usage as user: ' + error)
          }
        )
      } else if (this.view === 'admin' || this.view === 'support' || this.view === 'advisor') {
        const usageService = new UsageService(this.view)
        usageService.getCurrentForUser(username).then(
          response => {
            if (!_.isEmpty(response)) {
              // this.hasData = true
              this.accounts = response
            }
          }
        ).catch(
          error => {
            console.log('Error while requesting user usage as admin or support or advisor: ' + error)
          }
        )
      }
    }
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
