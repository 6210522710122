export const userFilterMixin = {
  computed: {
    userUsersFilterSet () {
      return this.filter.includes('useronly')
    },
    managerUsersFilterSet () {
      return this.filter.includes('manager')
    },
    piUsersFilterSet () {
      return this.filter.includes('pionly')
    },
    advisorUsersFilterSet () {
      return this.filter.includes('advisor')
    },
    supportUsersFilterSet () {
      return this.filter.includes('support')
    },
    adminUsersFilterSet () {
      return this.filter.includes('admin')
    }
  },
  methods: {
    showOnly (type) {
      const filters = this.filter.split(' ')
      if (this.filter && filters.length === 1) {
        const last = filters.pop()
        switch (last) {
          case 'useronly':
            if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          case 'manager':
            if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          case 'pionly':
            if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          case 'advisor':
            if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          case 'support':
            if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          case 'admin':
            if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          // Default Case
          default:
            this.filter = type
        }
      } else {
        this.filter = type
      }
      this.refresh()
    }
  }
}

export const accountFilterMixin = {
  computed: {
    nhrAccountsFilterSet () {
      return this.filter.includes('nhraccounts')
    },
    fauAccountsFilterSet () {
      return this.filter.includes('fauaccounts')
    },
    activeAccountsFilterSet () {
      return this.filter.includes('active') && !this.filter.includes('inactive')
    },
    inactiveAccountsFilterSet () {
      return this.filter.includes('inactive')
    }
  },
  methods: {
    showOnly (type) {
      const filters = this.filter.split(' ')
      if (this.filter && filters.length === 1) {
        const last = filters.pop()
        switch (last) {
          case 'nhraccounts':
            if (type === 'active' || type === 'inactive') {
              this.filter = last + ' ' + type
            } else if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          case 'fauaccounts':
            if (type === 'active' || type === 'inactive') {
              this.filter = last + ' ' + type
            } else if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          case 'active':
            if (type === 'fauaccounts' || type === 'nhraccounts') {
              this.filter = last + ' ' + type
            } else if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          case 'inactive':
            if (type === 'fauaccounts' || type === 'nhraccounts') {
              this.filter = last + ' ' + type
            } else if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          // Default Case
          default:
            this.filter = type
        }
      } else {
        this.filter = type
      }
      this.refresh()
    }
  }
}

export const projectFilterMixin = {
  computed: {
    nhrProjectsFilterSet () {
      return this.filter.includes('nhrprojects')
    },
    fauProjectsFilterSet () {
      return this.filter.includes('fauprojects')
    },
    aiProjectsFilterSet () {
      return this.filter.includes('aiprojects') && !this.filter.includes('nonaiprojects')
    },
    nonaiProjectsFilterSet () {
      return this.filter.includes('nonaiprojects')
    }
  },
  methods: {
    showOnly (type) {
      const filters = this.filter.split(' ')
      if (this.filter && filters.length === 1) {
        const last = filters.pop()
        switch (last) {
          case 'nhrprojects':
            if (type === 'aiprojects' || type === 'nonaiprojects') {
              this.filter = last + ' ' + type
            } else if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          case 'fauprojects':
            if (type === 'aiprojects' || type === 'nonaiprojects') {
              this.filter = last + ' ' + type
            } else if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          case 'aiprojects':
            if (type === 'fauprojects' || type === 'nhrprojects') {
              this.filter = last + ' ' + type
            } else if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          case 'nonaiprojects':
            if (type === 'fauprojects' || type === 'nhrprojects') {
              this.filter = last + ' ' + type
            } else if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          // Default Case
          default:
            this.filter = type
        }
      } else {
        this.filter = type
      }
      this.refresh()
    }
  }
}

export const invitationFilterMixin = {
  computed: {
    pendingFilterSet () {
      return this.filter.includes('pending')
    },
    acceptedFilterSet () {
      return this.filter.includes('accepted')
    },
    otherStatesFilterSet () {
      return this.filter.includes('otherstates')
    }
  },
  methods: {
    showOnly (type) {
      const filters = this.filter.split(' ')
      if (this.filter && filters.length === 1) {
        const last = filters.pop()
        switch (last) {
          case 'pending':
            if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          case 'accepted':
            if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          case 'otherstates':
            if (last === type) {
              this.filter = ''
            } else {
              this.filter = type
            }
            break
          // Default Case
          default:
            this.filter = type
        }
      } else {
        this.filter = type
      }
      this.refresh()
    }
  }
}
